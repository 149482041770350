import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["otherField", "roleInput", "familyForm", "btn"]

  connect() {
    if (this.roleInputTarget.value !== "") {
      this.btnTarget.classList.remove("btn-gray");
      this.btnTarget.classList.add("btn-sunny");
    }
  }

  toggleOtherField() {
    const role = this.roleInputTarget.value
    const parentValues = ["carrying_parent", "supporting_parent", "roleInput"]

    // method for change color btn
    this._changeColorButton()

    if (parentValues.includes(role)) return this._formForParentRole()

    this._formForOtherRole()
  }

  _formForOtherRole() {
    this.otherFieldTarget.classList.remove("hidden")
    this.familyFormTarget.classList.add("hidden")
  }

  _formForParentRole() {
    this.otherFieldTarget.classList.add("hidden")
    this.otherFieldTarget.value = ""
    this.familyFormTarget.classList.remove("hidden")
  }

  _changeColorButton() {
    if (this.roleInputTarget.value !== "") {
      this.btnTarget.classList.remove("btn-gray");
      this.btnTarget.classList.add("btn-sunny");
    }
  }
}
