import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = { dateToday: String, datePriorThirty: String, dataCurrentUser: Object, dataOtherUser: Object, datePregnancyStart: Number }
  static targets = ["allTimeRangeSwitch", "oneMonthRangeSwitch", "emotionalEvolutionSwitch", "emotionalEvolutionChart"]

  connect() {
    this._displayChartEmotionnalEvolution()
  }

  _displayChartEmotionnalEvolution() {
    this._emotionalEvolutionChart().render();
  }

  _emotionalEvolutionChart() {
    const options = this._compileDataForChart()
    options.series = this._singleOrMutipleUser()
    this.emotionalEvolutionObject = new ApexCharts(this.emotionalEvolutionChartTarget, options);
    return this.emotionalEvolutionObject
  }

  _chartZoom(chart, beginDate, endDate) {
    chart.zoomX(
        new Date(beginDate).getTime(),
        new Date(endDate).getTime()
      )
  }

  togglePeriod() {
    this._updatePeriodSwitch()
    const beginDate = (this.allTimeRangeSwitchTarget.classList.contains("active") ? this.datePregnancyStartValue : this.datePriorThirtyValue)
    this._chartZoom(this.emotionalEvolutionObject, beginDate, this.dateTodayValue)
  }

  _updatePeriodSwitch() {
    this.emotionalEvolutionSwitchTarget.classList.toggle("other-choice")
    this.allTimeRangeSwitchTarget.classList.toggle("active")
    this.oneMonthRangeSwitchTarget.classList.toggle("active")
  }

  _singleOrMutipleUser() {
    let seriesArray = [this.dataCurrentUserValue]
    if (Object.keys(this.dataOtherUserValue).length > 0) { seriesArray.push(this.dataOtherUserValue) }
    return seriesArray
  }

  _compileDataForChart() {
    const options = {
      chart: {
        id: 'area-datetime',
        type: 'area',
        height: 350,
        zoom: {
          autoScaleYaxis: true
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      colors: [
        // current user
        "#FCA45E",
        // other participant
        '#F9D18A'
      ],
      legend: {
        show: true,
        offsetY: 5
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
        style: 'hollow',
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dd-MM-yyyy'
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: true
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      }
    };
    return options
  }
}
