import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper", "modal"]
  static values = {
    selector: String,
    replaceWith: Object
  }

  targetModal

  // MAIN
  activateModal() {
    const { selectorValue, replaceWithValue } = this
    this.setTargetModal(selectorValue)
    this.replaceTargetModalContent(replaceWithValue)
    this.focusTargetModal()
  }

  cancel() {
    const { wrapperTarget } = this
    this.blur(wrapperTarget)
  }

  cancelIfActive({ target }) {
    if (target.classList.contains("active"))
      this.blur(target)
  }

  // REPLACEMENTS
  replaceTargetModalContent(replacementRulesDictionary) {
    const { title, paragraphs, buttons } = replacementRulesDictionary

    if (title != null) {
      this.replaceTitleBy(title)
    }

    if (paragraphs != null) {
      paragraphs.forEach(rulesDictionary => {
        const { selector } = rulesDictionary
        if (selector.constructor == String) {
          this.replaceParagraph(selector, rulesDictionary)
        }
      })
    }

    if (buttons != null) {
      buttons.forEach(rulesDictionary => {
        const { selector } = rulesDictionary
        if (selector.constructor == String) {
          this.replaceButton(selector, rulesDictionary)
        }
      })
    }
  }

  replaceTitleBy(newTitle) {
    const targetElement = this.findElementInModal("h1")
    targetElement.innerText = newTitle
  }

  replaceParagraph(selector, replacementRulesDictionary) {
    const targetElement = this.findElementInModal("p", selector)
    this.replaceElementAttributes(targetElement, replacementRulesDictionary)
  }

  replaceButton(selector, replacementRulesDictionary) {
    const targetElement = this.findElementInModal("button", selector)
    this.replaceElementAttributes(targetElement, replacementRulesDictionary)
    this.replaceAction(targetElement, replacementRulesDictionary)
  }

  replaceElementAttributes(element, replacementRulesDictionary, defaultClasses = []) {
    const { text, classes } = replacementRulesDictionary
    if (text)
      element.innerHTML = text
    if (classes)
      element.attributes.class = defaultClasses.concat(classes).join(" ")
  }

  replaceAction(element, replacementRulesDictionary) {
    const { route } = replacementRulesDictionary
    const parentForm = element.parentNode
    if (parentForm.tagName == "FORM")
      parentForm.action = route
  }

  // UTILITIES
  findElementInModal(tag_name, selector = null) {
    let targetSelector = tag_name
    if (selector != null)
      targetSelector = `${tag_name}${selector}`
    return this.targetModal.querySelector(targetSelector)
  }

  focusTargetModal() {
    this.targetModal.parentNode.classList.add("active")
  }

  blurTargetModal() {
    this.targetModal.parentNode.classList.remove("active")
  }

  blur(element) {
    element.classList.remove("active")
  }

  // SETTERS
  setTargetModal(selector) {
    const foundModal = document.querySelector(selector)
    if (foundModal) {
      this.targetModal = foundModal
    } else {
      throw `unable to find "${selector}"`
    }
  }
}
