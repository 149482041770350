import dragula from "dragula"
import autoScroll from "@mattlewis92/dom-autoscroller"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toRank", "ranked", "vetoed", "rankedFirst"]

  connect() {
    const options = {
      revertOnSpill: true,
      moves: this._moveCondition.bind(this),
      accepts: this._acceptCondition.bind(this)
    }

    const drake = dragula([this.toRankTarget, this.rankedTarget, this.vetoedTarget], options)

    drake.on("drop", this._updateRanking.bind(this))

    this._scroll(drake)
  }

  _scroll(drake) {
    autoScroll([
      window,
      document.querySelector('.page-wrapper')
    ], {
      margin: 200,
      scrollWhenOutside: true,
      maxSpeed: 6,
      autoScroll: () => drake.dragging
    }
    )
  }

  toggleFavorite(event) {
    event.target.classList.toggle("far")
    event.target.classList.toggle("fas")

    this._fetch(this.rankedFirstTarget.dataset.url, { first_favorited: (event.target.dataset.favorite !== "true").toString() })
  }

  _updateRanking(nameElement, targetBucket, _, followingNameElement) {
    let rankPointer = followingNameElement ? followingNameElement.dataset.rank : 10_000
    rankPointer = targetBucket == this.vetoedTarget ? -1 : rankPointer

    this._fetch(nameElement.dataset.url, { rank_pointer: rankPointer })
  }

  _fetch(url, params) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": csrfToken
      },
      method: "PATCH",
      body: JSON.stringify(params)
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  _moveCondition(_, __, handle) {
    return true
  }

  _acceptCondition(_, target) {
    return target !== this.toRankTarget
  }
}
