import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["saveChoiceButton", "loaderButton"]

  loader() {
    this.saveChoiceButtonTarget.classList.toggle("hidden")
    this.loaderButtonTarget.classList.toggle("hidden")
  }
}
