import { Controller } from "stimulus"

export default class extends Controller {
  static values = {offer_id: Number}
  static targets = ["serviceForm", "offerId", "input"]

  handleServiceOptionsChange(event) {
    event.preventDefault()
    this.inputTargets.forEach((input) => {
      if (input === event.currentTarget) {
        input.checked = true;
      } else {
        input.checked = false;
      }
    })
    this.element.submit();
  }

  handleSelectedOffer(event) {
    if (this.offerIdTarget.value !== "") {
      const previous = document.querySelector(`[data-service-id='${this.offerIdTarget.value}']`);
      previous.classList.remove("service-card-off");
      previous.firstElementChild.classList.remove("service-card-title-off");
    }
    event.currentTarget.classList.add("service-card-off");
    event.currentTarget.firstElementChild.classList.add("service-card-title-off");
    this.serviceFormTarget.classList.remove("hide-service-form");
    this.offerIdTarget.value = event.currentTarget.getAttribute("data-service-id")
setTimeout(() => {
    this.serviceFormTarget.scrollIntoView({ behavior: 'smooth' });
}, 100);
    console.log(this.serviceFormTarget)
  }
}
