import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modalSummary"]
  static values = {
    serviceId: Number,
  }

  openModalSummary(event) {
    event.preventDefault();
    this.modalSummaryTarget.style.display = "flex";
  }
}
