import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["invoiceInformations", "arrowIcon"]

  toggle() {
    this.invoiceInformationsTarget.classList.toggle("hidden")
    this.arrowIconTarget.classList.toggle("icon-rotation-180");
    this.arrowIconTarget.classList.toggle("icon-rotation-180-back");
  }
}
