import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = { scoreDiscover: Number, scoreT1: Number, scoreT2: Number, scoreT3: Number, scoreT4: Number }
  static targets = ["waypointChart"]

  connect() {
    this._displayChartEmotionnalWaypoint()
  }

  _displayChartEmotionnalWaypoint() {
    const waypointChart = new ApexCharts(this.waypointChartTarget, this._compileDataForChart());
    waypointChart.render();
  }
  _compileDataForChart() {
    const options = {
      series: [{
        name: 'Score',
        data: [this.scoreDiscoverValue, this.scoreT1Value, this.scoreT2Value, this.scoreT3Value, this.scoreT4Value]
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          colors: {
            ranges: [{
              from: -500,
              to: 0,
              color: '#799C87'
            }, {
              from: 0,
              to: 500,
              color: '#FB9551'
            }]
          },
          columnWidth: '80%',
        }
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          'Découverte', 'T1', 'T2', 'T3', 'T4']
      }
    };
    return options
  }


}
