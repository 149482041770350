import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["photos"]

  submit(event) {
    // TODO: refact with targets
    const form = event.target.parentNode.parentNode;
    // TODO: fix turbo
    form.requestSubmit();
    // form.submit()
  }
}
