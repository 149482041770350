import { Controller } from "stimulus"

// Connects to data-controller="filter-list"
export default class extends Controller {
  static targets = ["list", "btnDisplay", "btnRemove"];
  static values = { type: String }

  display(event) {
    event.preventDefault();
    this.listTarget.classList.add("tag-tab-offers");
    this.listTarget.classList.remove("tag-tab-list");
    this.btnDisplayTarget.classList.add("none-filters");
    this.btnRemoveTarget.classList.remove("none-filters");
  }

  remove(event) {
    event.preventDefault();
    this.listTarget.classList.remove("tag-tab-offers");
    this.listTarget.classList.add("tag-tab-list");
    this.btnDisplayTarget.classList.remove("none-filters");
    this.btnRemoveTarget.classList.add("none-filters");
  }
}
