import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = { occurencePositiveNegative: Array, positiveColor: String, negativeColor: String }
  static targets = ["dominantPie"]

  connect() {
    this._displayDominantPieChart();
  }

  _displayDominantPieChart() {
    const options = this._compileChartOptions()
    const pieChart = new ApexCharts(this.dominantPieTarget, options);
    pieChart.render();
  }

  _compileChartOptions() {
    const options = {
      series: [this.occurencePositiveNegativeValue[0], this.occurencePositiveNegativeValue[1]],
      colors: [
        // positiv percentage
        this.positiveColorValue,
        // negative percentage
        this.negativeColorValue],
      chart: {
        width: 380,
        heigth: 300,
        type: 'pie',
        animations: {
          enabled: false
        }
      },
      legend: {
        position: 'bottom'
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(0) + " % "
        }
      },
      labels: ['Emotion positive', 'Emotion négative'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
        }
      }]
    };
    return options
  }
}
