// app/javascript/controllers/toggle_date_input_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "placeholder"]

  connect() {
    if (!this.inputTarget.value) {
      this.inputTarget.type = 'text';
      this.placeholderTarget.style.display = "block";
      this.inputTarget.classList.add("hidden-input");
    } else {
      this.inputTarget.type = 'date';
      this.placeholderTarget.style.display = "none";
      this.inputTarget.classList.remove("hidden-input");
    }
  }

  toggleType() {
    console.log("hello")
    if (this.inputTarget.type === 'text') {
      this.inputTarget.type = 'date';
      this.placeholderTarget.style.display = "none";
      this.inputTarget.classList.remove("hidden-input");
    }
  }

  resetType() {
    if (!this.inputTarget.value) {
      this.inputTarget.type = 'text';
      this.placeholderTarget.style.display = "block";
      this.inputTarget.classList.add("hidden-input");
    }
  }
}
