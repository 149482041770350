import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wheelSvg", "wheel", "dimensionText", "dimensionList", "emotionField", "submit", "emotionTitle", "dimensionTitle"]
  static values = { index: Number, totalSections: Number, url: String}

  connect() {
    this.index = this.indexValue
    this._preventScroll()
  }

  selectDimension() {
    const clickedPetal = parseInt(event.target.dataset.petalNumber, 10)
    const rotationSteps = clickedPetal === this.index ? 0 : this._getRotationSteps(clickedPetal)
    this._moveWheel(rotationSteps)
    this._showDimensionList(clickedPetal)
    if (rotationSteps === 0) return

    this._rotateDimensionTexts(rotationSteps)
    this.index = clickedPetal
  }

  selectEmotion() {
    const emotionId = event.target.dataset.emotionId
    event.target.classList.add("touched")
    this.emotionFieldTarget.value = emotionId
    setTimeout(() => {this.submitTarget.click()}, 800);
  }

  _getRotationSteps(clickedPetal) {
    const clickedDimensionText = this.dimensionTextTargets.find(dimension => parseInt(dimension.dataset.dimensionNumber, 10) === clickedPetal)
    const currentPosition = parseInt(clickedDimensionText.dataset.dimensionPosition, 10)
    const stepsClockwise = this.totalSectionsValue - currentPosition + 1
    const stepsCounterClockwise = currentPosition - 1

    return stepsClockwise < stepsCounterClockwise ? stepsClockwise : - stepsCounterClockwise
  }

  _rotateDimensionTexts(rotationSteps) {
    this.dimensionTextTargets.forEach((dimensionText) => {
      let newPosition = this._modulo(parseInt(dimensionText.dataset.dimensionPosition, 10) + rotationSteps, this.totalSectionsValue)
      if (newPosition <= 0) {newPosition += this.totalSectionsValue }
      this._updateDimensionTextCss(dimensionText, newPosition)
      dimensionText.dataset.dimensionPosition = newPosition
    })
  }

  _moveWheel(rotationSteps) {
    this.wheelTarget.classList.add("wheel-bottom")
    if (rotationSteps === 0) return

    const sectionAngle = 360 / this.totalSectionsValue
    const svgCurrentCssRotationAngle = this.wheelSvgTarget.style.transform.match(new RegExp(/\((.+)deg/))
    const initialRotationInDegrees = svgCurrentCssRotationAngle !== null ? parseFloat(svgCurrentCssRotationAngle[1]) : 0
    this.wheelSvgTarget.style.transform = `rotate(${initialRotationInDegrees + (rotationSteps * sectionAngle)}deg)`
  }

  _updateDimensionTextCss(dimensionText, newPosition) {
      dimensionText.className = ""
      dimensionText.classList.add("dimension-box", `position-${newPosition}`)
      if (dimensionText.dataset.dimensionNumber !== "4" && dimensionText.dataset.dimensionNumber !== "5") {
        dimensionText.classList.add("color-white")
      }
  }

  _showDimensionList(selectedDimension) {
    this.emotionTitleTarget.classList.remove('hidden')
    this.dimensionTitleTarget.classList.add('hidden')
    this.dimensionListTargets.forEach((dimensionList) => {
      dimensionList.classList.toggle("hidden", dimensionList.dataset.dimensionNumber !== selectedDimension.toString())
    })
  }

  _preventScroll() {
    document.querySelector(".page-wrapper").style.overflowY = "hidden"
  }

  _modulo(number, modulo) {
    return ((number % modulo) + modulo) % modulo
  }
}
