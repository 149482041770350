import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["validationButton", "editPanel", "editForm"]

  validateAndPersistEdition(event) {
    event.preventDefault()

    const { editFormTarget } = this
    const form = editFormTarget
    if (!form)
      return
    
    this.appendValidationInput(form)
    form.submit()
  }

  appendValidationInput(target) {
    const input = document.createElement("input")
    const attributes = {
      type: 'hidden',
      name: 'is_section_validation',
      value: true
    }
    Object.keys(attributes).forEach(key => input.setAttribute(key, attributes[key]))
    target.insertBefore(input, target.firstChild);
  }
}
