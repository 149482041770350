import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper", "top", "buttonTopThreshold", "toTopButton"]
  static values = {
    target: String
  }

  toTopIsShown = true

  connect() {
    if (!this.hasToTopButtonTarget) { return }

    // If wrapper target undefined, wrapper is page-wrapper
    const wrapper = this.hasWrapperTarget ? this.wrapperTarget : document.querySelector(".page-wrapper")

    this.handleToTopVisibility(wrapper)
    wrapper.addEventListener("scroll", () => this.handleToTopVisibility(wrapper))
  }

  // MAIN
  toTop() {
    const { topTarget } = this

    topTarget.scrollIntoView(true)
  }

  // VISIBILITY
  handleToTopVisibility(wrapper) {
    if (
      this.rectsIntersect(
        this.getWrapperRect(wrapper),
        this.getElementRect(this.buttonTopThresholdTarget)
      ) !== this.toTopIsShown
    ) {
      this.toTopIsShown = !this.toTopIsShown;
      this.toTopIsShown ? this.displayToTop() : this.hideToTop();
    }
  }

  hideToTop() {
    this.toTopButtonTarget
        .style
        .display = "none"
  }

  displayToTop() {
    this.toTopButtonTarget
        .style
        .display = "flex"
  }

  // UTILITIES
  getWrapperRect(wrapper) {
    let x = wrapper.scrollLeft
    let y = wrapper.scrollTop
    let w = wrapper.offsetWidth
    let h = wrapper.offsetHeight
    return [x, y, x + w, y + h]
  }

  getElementRect(element) {
    let x = 0, y = 0
    let w = element.offsetWidth, h = element.offsetHeight
    while (element.offsetParent !== null) {
        x += element.offsetLeft
        y += element.offsetTop
        element = element.offsetParent
    }
    return [x, y, x + w, y + h]
  }

  rectsIntersect(a, b) {
    return a[0] < b[2] &&
           a[2] > b[0] &&
           a[1] < b[3] &&
           a[3] > b[1]
  }
}
