import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["textField"]

  connect() {
    this.textFieldTargets.forEach((el) => {
      this._setHeight(el)
    })
  }

  updateHeight(event) {
    event.target.style.height = "auto"
    event.target.style.height = (event.target.scrollHeight) + "px"
  }

  _setHeight(el) {
    el.style.height = "auto"
    el.style.height = (el.scrollHeight) + "px"
  }
}


