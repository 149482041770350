import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'mood']

  update() {
    if (this.formTarget.value == 1) {
      this.moodTarget.innerHTML = 'Très triste'
    } else if (this.formTarget.value == 2)  {
      this.moodTarget.innerHTML = 'Triste'
    } else if (this.formTarget.value == 3)  {
      this.moodTarget.innerHTML = 'Neutre'
    } else if (this.formTarget.value == 4)  {
      this.moodTarget.innerHTML = 'Heureux'
    } else if (this.formTarget.value == 5)  {
      this.moodTarget.innerHTML = 'Très heureux'
    }
  }
}
