import intlTelInput from "intl-tel-input"
import "intl-tel-input/build/css/intlTelInput.css"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["phone"]

  connect() {
    const options = {
      utilsScript: require("intl-tel-input/build/js/utils.js"),
      autoPlaceholder: "aggressive",
      initialCountry: "fr",
      preferredCountries: ["fr", "be", "ch", "lu", "ca"],
      hiddenInput: "phone"
    }

    intlTelInput(this.phoneTarget, options)
  }
}
