import { Controller } from "stimulus"

// Connects to data-controller="pregnancy-date"
export default class extends Controller {
  static targets = ["input"];

  increment() {
    this.inputTarget.value = parseInt(this.inputTarget.value, 10) + 1;
  }

  decrement() {
    const value = parseInt(this.inputTarget.value, 10);
    this.inputTarget.value = value > 1 ? value - 1 : 1;
  }
}
