import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "message", "inputField", "uploadForm", "submitButton", "preview"]

  displayMessage() {
    if(this.inputFieldTarget.files.length === 1) {
      this.messageTarget.textContent = this.inputFieldTarget.files[0].name
      this.messageTarget.classList.add("text-light")
      this.messageTarget.classList.add("text-light-grass")
    } else {
      this.messageTarget.textContent = "Aucun fichier choisi"
    }
    if (this.previewTarget) this._updatePreview(this.inputFieldTarget.files[0])
    this.formTarget.submit();
  }

  openFilePicker() {
    this.inputFieldTarget.click()
  }

  submitForm() {
    this.submitButtonTarget.click()
  }

  _updatePreview(file = null) {
    if(!this.previewTarget.dataset.originalSource) this.previewTarget.dataset.originalSource = this.previewTarget.src
    if(file) {
      this.previewTarget.src = URL.createObjectURL(file)
    } else {
      this.previewTarget.src = this.previewTarget.dataset.originalSource
    }
  }
}
