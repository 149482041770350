import Swiper from 'swiper/bundle'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pagination", "rightNav", "leftNav"]
  static values = { type: String }
  
  connect() {
    if (this.typeValue == "minisite") {
      this._setWebSwiper()
    } else if (this.typeValue == "entries") {
      this._setEntriesSwiper()
    }
  }

  _setEntriesSwiper() {
    const entriesOptions = {
      direction: 'horizontal',
      loop: true,

      pagination: {
        el: this.paginationTarget
      }
    }

    const entriesSwiper = document.querySelector(".entries-swiper")

    this.swiper = new Swiper(entriesSwiper, entriesOptions);
  }

  _setWebSwiper() {
    const webOptions = {
      direction: "horizontal",
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      spaceBetween: 30,
      effect: "fade",
      
      navigation: {
        nextEl: this.rightNavTarget,
        prevEl: this.leftNavTarget,
      }
    }

    document.querySelectorAll(".web-swiper").forEach((webSwiper) => {
      this.swiper = new Swiper(webSwiper, webOptions);
    })
  }
}
