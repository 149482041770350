import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  submit() {
    const { formTarget } = this
    formTarget.requestSubmit()
  }
}
