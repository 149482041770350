import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
                      "dayToDayDimensionSwitch",
                      "dayToDayPositiveNegativeSwitch",
                      "daytoDaySwitch",
                      "dayToDayDimensionChartView",
                      "dayToDayPositiveNegativeChartView",
                      "dayToDayChartDimensions",
                      "dayToDayChartPositiveNegative",
                      "dayToDayChartDimensionsOtherParticipant",
                      "dayToDayChartPositiveNegativeOtherParticipant"
                    ]

  connect() {
    this.dayToDayChartDimensionsOtherParticipantTarget.scrollTo(this.dayToDayChartDimensionsOtherParticipantTarget.scrollWidth, 0);
    this.dayToDayChartDimensionsTarget.scrollTo(this.dayToDayChartDimensionsTarget.scrollWidth, 0);
  }

  toggleStats() {
    this._updateDayToDaySwitch()
    this._updateStatsView()
    this.dayToDayChartPositiveNegativeTarget.scrollTo(this.dayToDayChartPositiveNegativeTarget.scrollWidth, 0);
    this.dayToDayChartPositiveNegativeOtherParticipantTarget.scrollTo(this.dayToDayChartPositiveNegativeOtherParticipantTarget.scrollWidth, 0);
  }

  _updateDayToDaySwitch() {
    this.daytoDaySwitchTarget.classList.toggle("other-choice")
    this.dayToDayDimensionSwitchTarget.classList.toggle("active")
    this.dayToDayPositiveNegativeSwitchTarget.classList.toggle("active")
  }

  _updateStatsView() {
    this.dayToDayDimensionChartViewTarget.classList.toggle("hidden")
    this.dayToDayPositiveNegativeChartViewTarget.classList.toggle("hidden")
  }
}
