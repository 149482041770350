import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pageWithoutMenu", "menuContent", "menuTitle", "topbar", "logo",
  "menuOpen", "menuClose", "settingProfil", "settingProfilMenu", "numberParticipants",
  "btnPregnancy", "settingHome", "topbarPro", "imagePro", "heightopbarPro", "footerPro"]

  toggle(event) {
    this.menuContentTarget.classList.toggle("hidden");
    this.logoTarget.classList.toggle("hidden");
    this.topbarTarget.classList.toggle("topbar-menu");
    this.menuOpenTarget.classList.toggle("hidden");
    this.menuCloseTarget.classList.toggle("hidden");
    this.settingProfilTarget.classList.toggle("hidden");
    this.settingProfilMenuTarget.classList.toggle("hidden");
    this.settingHomeTarget.classList.toggle("setting-profile");
    this.numberParticipantsTarget.classList.toggle("hidden");
    this.btnPregnancyTarget.classList.toggle("hidden");
    this.element.classList.toggle("all-menu");
    this.pageWithoutMenuTarget.classList.toggle("hidden");
  }

  togglePro(event) {
    this.topbarProTarget.classList.toggle("topbar-menu");
    this.menuContentTarget.classList.toggle("hidden");
    this.element.classList.toggle("all-menu");
    this.btnPregnancyTarget.classList.toggle("hidden");
    this.logoTarget.classList.toggle("hidden");
    this.menuCloseTarget.classList.toggle("hidden");
    this.imageProTarget.classList.toggle("user-avatar-pro");
    this.heightopbarProTarget.classList.toggle("heigh-topbar-pro");
    this.footerProTarget.classList.toggle("hidden");
  }
}
