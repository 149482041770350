import { Controller } from "stimulus"

export default class extends Controller {
  submit(event) {
    // TODO: l'ancienne méthode produisait un trigger du sibmit du DOM
    // => empêchai turbo de fonctionner
    // => demander à Charlotte le pourquoi de la méthode commentée
    event.target.form.requestSubmit() //dispatchEvent(new CustomEvent('submit', { bubbles: true }))
  }

  toggleClamped(event) {
    event.target.classList.toggle("clamped")
  }
}

