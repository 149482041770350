import { Controller } from "stimulus"

export default class extends Controller {
  static values = {url: String}

  initialize() {
    this.uploadFile = this._uploadFile.bind(this)
  }

  connect() {
    document.addEventListener("trix-file-accept", this.preventWrongUpload)
    document.addEventListener("trix-attachment-remove", this.deleteAsset)
    document.addEventListener("trix-attachment-add", this.uploadFile)
  }

  disconnect() {
    document.removeEventListener("trix-file-accept", this.preventWrongUpload)
    document.removeEventListener("trix-attachment-remove", this.deleteAsset)
    document.removeEventListener("trix-attachment-add", this.uploadFile)
  }

  preventWrongUpload(event) {
    const acceptedTypes = ['image/jpeg', 'image/png']
    if (acceptedTypes.includes(event.file.type)) return

    event.preventDefault()
    alert("Merci de fournir un fichier jpg ou png uniquement !")
  }

  deleteAsset(event) {
    const attachment = event.attachment
    const public_id_regex = /rich_text_media\/(\w+)\..+/
    const public_id = attachment.attachment.previewURL.match(public_id_regex)[1]
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")

    fetch (`/article_attachments`, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": csrfToken
      },
      method: "DELETE",
      body: JSON.stringify({"public_id": public_id})
    })
  }

  _uploadFile(event) {
    const attachment = event.attachment
    if (!attachment.file) return

    const formData = new FormData()

    formData.append("file", attachment.file)
    formData.append("upload_preset", "rich_text_attachment")

    const params = {
      method: "POST",
      body: formData
    }

    fetch(this.urlValue, params)
      .then(response => response.json())
      .then((data) => {
        if (data.error) throw "Upload failed"

        attachment.setAttributes({url: data.url, href: data.url})
      });
  }
}
