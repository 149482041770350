import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'modalSummary', 'modalValidate', 'form']

  connect() {
    this.navbar = document.querySelector(".topbar-container");
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("open_modal")) {
      this.openModalDraftBooking();
    }
  }

  openModalDraftBooking() {
    this.modalTarget.style.display = "flex";
    this.navbar.style.display = "none";
  }

  openModal(event) {
    event.preventDefault();
    this.modalTarget.style.display = "flex";
    this.navbar.style.display = "none";
  }

  closeModal(event) {
    event.preventDefault();
    this.modalTarget.style.display = "none";
    this.navbar.style.display = "flex"
  }

  openModalSummary(event) {
    event.preventDefault();
    const serviceId = event.currentTarget.dataset.serviceId;
    fetch(`/api/v1/services/${serviceId}.json`)
      .then(response => response.json())
      .then(serviceData => {
        this.modalSummaryTarget.style.display = "flex";
        this.navbar.style.display = "none";
        // Modifier la date du service sur la modale
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const date = new Date(serviceData.start_at);
        this.modalSummaryTarget.querySelector('#date-service').innerHTML = date.toLocaleDateString('fr-FR', options).replace(/ /g, ' ').replace(',', ' à');
        // On met le service correct en params du form
        const hiddenServiceIdField = this.modalSummaryTarget.querySelector('#hidden-service-id');
        hiddenServiceIdField.value = serviceData.id;
        const locationText = this.modalSummaryTarget.querySelector('.location-service');
        if (serviceData.location !== "") {
          locationText.innerHTML = `<p>Sur place : </p>
            <p>${serviceData.location},</p>
            <p>${serviceData.department}</p>`
        }
      });
  }

  closeModalSummary(event) {
    event.preventDefault();
    this.modalSummaryTarget.style.display = "none";
    this.navbar.style.display = "flex"
  }

  openModalValidate(event) {
    event.preventDefault();
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        'X-CSRF-Token': token,
        "Accept": "text/plain"
      },
      body: new FormData(this.formTarget),
    })
    .then(response => {
      if (response.ok) {
        // Requête réussie, ouvrir la modal
        this.modalValidateTarget.style.display = "flex";
        this.navbar.style.display = "none";
      } else {
        console.error("Erreur lors de la requête.");
      }
    })
    // this.modalValidateTarget.style.display = "flex";
  }

  closeModalValidate(event) {
    event.preventDefault();
    this.modalValidateTarget.style.display = "none";
    this.modalTarget.style.display = "none";
    this.navbar.style.display = "flex"
  }
}
