import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "preview",
    "centimeter",
    "wrapper",
    "previewPanel"
  ]
  static values = {
    pageWidthCm: Number,
    pageHeightCm: Number,
    mobileDevice: Boolean,
    previewMargin: Number
  }

  connect() {
    // TODO: replace connect + timeout with Stimulus 3 [name]TargetConnected() callback
    setTimeout(() => { this.resizePreview() }, 500);
  }

  resizePreview() {
    // TODO: improve this with some debounce or throttle
    // Currently works fine if resizing very slowly, otherwize only works on expand not shrink because of min / max
    const previewAreaDimensionsCm = this._getPreviewRenderedDimensionsCm()
    const resizeRatio = this._setResizeRatio(previewAreaDimensionsCm)
    this._applyResizeCss(resizeRatio)
  }

  _applyResizeCss(ratio) {
    this.previewTargets.forEach((target) => {
      target.style.transform = `scale(${ratio})`
      target.parentNode.style.height = `${ratio * this.pageHeightCmValue}cm`
      target.parentNode.style.width = `${ratio * this.pageWidthCmValue}cm`
      target.parentNode.style.margin = "0 auto"
    })
  }

  _getPreviewRenderedDimensionsCm() {
    const desktopPreviewWidthPx = Math.max(this.previewTarget.clientWidth, this.previewPanelTarget.clientWidth)
    const mobilePreviewWidthPx = this.previewPanelTarget.clientWidth * (1 - 2 * this.previewMarginValue)
    const previewWidthPx = this.mobileDeviceValue ? mobilePreviewWidthPx : desktopPreviewWidthPx
    const previewHeightPx = this.hasWrapperTarget ? this.wrapperTarget.clientHeight : null
    return { width: this._pixelsToCm(previewWidthPx), height: this._pixelsToCm(previewHeightPx) }
  }

  _setResizeRatio(previewAreaDimensionsCm) {
    const ratioToFitWidth = previewAreaDimensionsCm.width / this.pageWidthCmValue
    const ratioToFitHeight = previewAreaDimensionsCm.height / this.pageHeightCmValue
    return this.mobileDeviceValue ? ratioToFitWidth : Math.min(ratioToFitHeight, ratioToFitWidth)
  }

  _pixelsToCm(pixelValue) {
    return pixelValue / this.centimeterTarget.clientWidth
  }
}
