import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["closeButton"]
  static values = {
    autoClose: Boolean
  }

  connect() {
    if (this.autoCloseValue) this._startTimer()
  }

  hide() {
    this.element.classList.add("hidden")
    this.element.style.removeProperty("max-height")
    this.element.style.removeProperty("padding")
    this.element.style.removeProperty("overflow")
  }

  _startTimer() {
    setTimeout(this._animateHiding.bind(this), 3_000)
  }

  _animateHiding() {
    this.closeButtonTarget.style.opacity = "0"
    this.element.style.maxHeight = "0"
    this.element.style.padding = "0"
    this.element.style.overflow = "hidden"

    setTimeout(this.hide.bind(this), 1_000)
  }
}
