import { Controller } from "stimulus"

export default class extends Controller {
  static values = { message: String }

  connect() {
    this.defaultMessage = "Are you sure?"
    if (!("action" in this.element.dataset)) this.element.dataset.action = "click->confirm#show"
  }

  show() {
    const confirmationMessage = this.hasMessageValue ? this.messageValue : this.defaultMessage
    if (window.confirm(confirmationMessage)) return;

    event.preventDefault()
    event.stopImmediatePropagation()
  }
}
