import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["secondQuote"]

  toggleDisplaySecondQuote() {
    this.secondQuoteTargets.forEach((element) => {
      element.classList.toggle("hidden")
    })
  }
}

