import { Controller } from "stimulus"
import Swiper, { Navigation, Pagination } from 'swiper'

export default class extends Controller {
  static targets = ["pagination", "next", "prev"]

  connect() {
    const moodsOptions = {
      direction: "horizontal",
      grabCursor: true,
      centeredSlides: true,
      spaceBetween: 30,
      effect: "fade",

      pagination: {
        el: this.paginationTarget,
      },

      navigation: {
        nextEl: this.nextTarget,
      }
    }

    const moodsSwiper = document.querySelector(".moods-swiper");

    this.swiper = new Swiper(moodsSwiper, moodsOptions);
  }
}
