import { Controller } from "stimulus";
import $ from "jquery";
import { get } from "@rails/request.js"
import "select2";
import 'select2/dist/css/select2.min.css';

export default class extends Controller {
  static values = { options: Object, isForJob: Boolean }

  connect() {
    $(this.element).select2(this.optionsValue);
    if (this.isForJobValue){
      $(this.element).on("select2:select", (e) => {
        this._turboStreamCall()
      });
      $(this.element).on("select2:unselect", (e) => {
        this._turboStreamCall()
      });
    }
  }

  _turboStreamCall() {
    let jobs_ids = $(this.element).select2().val();
    let target = "specific_specialities"
    get(`/pro_space/companies/yourjobs?target=${target}&jobs_ids=${jobs_ids}`, {
      responseKind: "turbo-stream"
    })
  }
}
