import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newDateInput"]

  addDate() {
    const dateField = '<div class="form-group datetime inquiry_inquiry_date_date"><label class="datetime required" for="inquiry_inquiry_date_date">Ou le ...</label><div class="d-flex flex-row justify-content-between align-items-center"><input class="form-control mx-1 datetime custom-input datefield-without-calendar" autofocus="autofocus" type="datetime-local" name="inquiry[inquiry_date][date][]" id="inquiry_inquiry_date_date"></div></div>'
    this.newDateInputTarget.insertAdjacentHTML("beforeBegin", dateField)
  }
}
