import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
                      "topDimensionChartSwitch",
                      "topEmotionChartSwitch",
                      "statsTopSwitch",
                      "topDimensionChartView",
                      "topEmotionChartView"
                    ]

  toggleStats() {
    this._updateStatsSwitch()
    this._updateStatsView()
  }

  _updateStatsSwitch() {
    this.statsTopSwitchTarget.classList.toggle("other-choice")
    this.topDimensionChartSwitchTarget.classList.toggle("active")
    this.topEmotionChartSwitchTarget.classList.toggle("active")
  }

  _updateStatsView() {
    this.topDimensionChartViewTarget.classList.toggle("hidden")
    this.topEmotionChartViewTarget.classList.toggle("hidden")
  }
}
