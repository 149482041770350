import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["webMenuContainer", "hamburger"]

  displayResponsiveMenu(event) {
    event.preventDefault()
    this.webMenuContainerTarget.classList.toggle("responsive")
  }

  animateHamburger() {
    this.hamburgerTarget.classList.toggle("active")
  }
}
