import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spot", "entriesForADay"]
  static values = { url: String }

  connect() {
    this._scrollCurrentSpotToView()
  }

  select(event) {
    const date = event.target.dataset.date

    this._desactivateSpot()
    event.target.classList.add("active")

    this.entriesForADayTarget.src = `${this.urlValue}?date=${date}`
  }

  scrollLeft(event) {
    var active_spot = this.spotTargets.find(spot => spot.classList.contains("active") );

    if (typeof active_spot !== 'undefined') {
      var index_of_active_spot = this.spotTargets.indexOf(active_spot)
      var new_index = Math.min(index_of_active_spot + 1, (this.spotTargets.length -1))
      this.spotTargets[new_index].click()
      this._scrollCurrentSpotToView()
    }
  }

  scrollRight(event) {
    var active_spot = this.spotTargets.find(spot => spot.classList.contains("active") );

    if (typeof active_spot !== 'undefined') {
      var index_of_active_spot = this.spotTargets.indexOf(active_spot)
      var new_index = Math.max(index_of_active_spot - 1, 0)
      this.spotTargets[new_index].click()
      this._scrollCurrentSpotToView()
    }
  }

  _desactivateSpot() {
    this.spotTargets.forEach((spot) => {
      spot.classList.remove("active")
    })
  }

  _scrollCurrentSpotToView() {
    this.spotTargets.forEach((spot) => {
      if (!spot.classList.contains("active")) return

      spot.scrollIntoView({ block: "center", inline: "center" })
    })
  }

  selectToday() {
    this.spotTargets[0].click()
    this._scrollCurrentSpotToView()
  }
}
