import { Controller } from "stimulus"

// Connects to data-controller="pregnancy-date"
export default class extends Controller {
  static targets = ["tab", "content"]

  showTab(event) {
    const tabId = event.currentTarget.dataset.tab;
    this.tabTargets.forEach(tab => {
      tab.classList.remove("active");
    });

    this.contentTargets.forEach(content => {
      content.classList.remove("active");
    });

    const activeTab = this.tabTargets.find(tab => tab.dataset.tab === tabId);
    activeTab.classList.add("active");

    const activeContent = this.contentTargets.find(content => content.dataset.content === tabId);
    activeContent.classList.add("active");
  }
}
