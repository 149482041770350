import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = { emotionData: Array }
  static targets = ["topEmotion"]

  connect() {
    this._displayTop3Emotion()
  }

  _displayTop3Emotion() {
    const options = this._compileChartOptions()
    options.series = this.emotionDataValue
    const chartTopEmotion = new ApexCharts(this.topEmotionTarget, options)
    chartTopEmotion.render()
  }

  _compileChartOptions() {
    const options = {
      chart: {
        height: 300,
        width: 300,
        type: 'bar',
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      colors: [
        // second position
        "#6B8375",
        // first position
        "#83A593",
        // third position
        "#D0DED4"
      ],
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top',
          },
        }
      },
      tooltip: {
        enabled: false
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: 12,
        style: {
          fontSize: '12px',
          colors: ["#FFFFFF"]
        }
      },
      xaxis: {
        categories: this._getEmotionNames(),
        position: 'bottom',
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false
        }
      },
      grid: {
        show: false
      },
    };
    return options
  }

  _getEmotionNames() {
    const emotionNames = []
    this.emotionDataValue.forEach(emotionData => {
      emotionNames.push(emotionData.name)
    })
    return emotionNames
  }
}
