import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mobile", "tablet"]

  connect() {
    if (window.screen.width >= 700) {
      this.mobileTarget.style.display = 'none';
      this.tabletTarget.style.display = 'block';
    } else {
      this.mobileTarget.style.display = 'block';
      this.tabletTarget.style.display = 'none';
    }
  }
}
