import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["genderSwitch", "femaleSwitch", "maleSwitch", "listSwitch", "myListSwitch", "ourListSwitch", "myList", "ourList", "genderList"]

  toggleGender() {
    this._updateGenderSwitch()
    this._updateGenderView()
  }

  toggleList() {
    this._updateListSwitch()
    this._updateListView()
  }

  _updateGenderSwitch() {
    this.genderSwitchTarget.classList.toggle("other-choice")
    this.femaleSwitchTarget.classList.toggle("active")
    this.maleSwitchTarget.classList.toggle("active")
  }
  
  _updateListSwitch() {
    this.listSwitchTarget.classList.toggle("other-choice")
    this.myListSwitchTarget.classList.toggle("active")
    this.ourListSwitchTarget.classList.toggle("active")
  }

  _updateGenderView() {
    this.genderListTargets.forEach(list => {
      list.classList.toggle("hidden")
    });
  }
  
  _updateListView() {
    this.myListTarget.classList.toggle("hidden")
    this.ourListTarget.classList.toggle("hidden")
  }
}
