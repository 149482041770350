import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
                    "buttonConfirm",
                    "bookingDeleteMessage"
                   ]

  displayConfirmationMessage() {
    this.bookingDeleteMessageTarget.classList.toggle("hidden")
    if (this.buttonConfirmTarget.innerHTML === "Annuler") {
      this.buttonConfirmTarget.innerHTML = "Supprimer mon service"
    } else {
      this.buttonConfirmTarget.innerHTML = "Annuler"
    }
  }
}
