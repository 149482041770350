import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = { dimensionData: Array }
  static targets = ["topDimensionChart"]

  connect() {
    this._displayTop3Dimension()
  }

  _displayTop3Dimension() {
    const options = this._compileChartOptions()
    options.series = this.dimensionDataValue
    const chartTopDimension = new ApexCharts(this.topDimensionChartTarget, options)
    chartTopDimension.render()
  }

  _compileChartOptions() {
    const options = {
      chart: {
        height: 300,
        width: 300,
        type: 'bar',
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      colors: [
        // second position
        "#E59159",
        // first position
        "#FDA060",
        // third position
        "#E9D2AE"
      ],
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top',
          },
        }
      },
      tooltip: {
        enabled: false
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: 10,
        style: {
          fontSize: '12px',
          colors: ["#FFFFFF"]
        }
      },
      xaxis: {
        categories: this._getCategories(),
        position: 'bottom',
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false
        }
      },
      grid: {
        show: false
      },
    };
    return options
  }

  _getCategories() {
    const categories = []
    this.dimensionDataValue.forEach(categoryData => {
      categories.push(categoryData.name)
    })
    return categories
  }
}
