import { Controller } from "stimulus"

export default class extends Controller {
  static values = { payload: Object, sendNow: Boolean }

  connect() {
    if (this.hasSendNowValue && this.sendNowValue) this.send()
  }

  send() {
    const userAgent = navigator.userAgent
    if (!userAgent.includes("Turbo Native")) return

    window
      .webkit
      .messageHandlers
      .nativeApp
      .postMessage(this.payloadValue)
  }
}