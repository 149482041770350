import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainText", "lossText", "babyForm"]

  displayLossText() {
    this.mainTextTarget.classList.add("hidden")
    this.lossTextTarget.classList.remove("hidden")
  }

  displayBabyForm() {
    this.babyFormTarget.classList.remove("hidden")
  }
}
