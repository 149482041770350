import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formInquiryRefused", "formInquiryAccepted"]

  displayFormInquiryRefused() {
    this._removeHiddenClass(this.formInquiryRefusedTarget)
    this._addHiddenClass(this.formInquiryAcceptedTarget)
  }

  displayFormInquiryAccepted() {
    this._removeHiddenClass(this.formInquiryAcceptedTarget)
    this._addHiddenClass(this.formInquiryRefusedTarget)
  }

  _removeHiddenClass(target) {
    target.classList.remove('hidden')
  }
  _addHiddenClass(target) {
    target.classList.add('hidden')
  }
}
