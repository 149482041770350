import { Controller } from "stimulus"
import Swiper from 'swiper/bundle'

export default class extends Controller {
  static targets = ["pagination"]

  connect() {
    const articlesOptions = {
      slidesPerView: 1.2,
      loop: true,
      spaceBetween: 10,

      pagination: {
        el: this.paginationTarget
      }
    }

    const articlesSwiper = document.querySelector(".articles-swiper");

    this.swiper = new Swiper(articlesSwiper, articlesOptions);
  }
}
