import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["anchor"]

  scrollToAnchor() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('query')) {
      this.anchorTarget.scrollIntoView();
    }
  }

  connect() {
    const queryString = window.location.search;
    const anchor = window.location.hash;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('tabbed') || urlParams.get('commit') || urlParams.get('selected') || anchor) {
      this.anchorTarget.scrollIntoView()
    }
  }
}
